import React from "react"

import Layout from "@src/components/Layout"
import Row from "../../V2/components/Row/Row"
import ImageRow from "../../V2/Features/components/ImageRow/ImageRow"
import Button from "../../../components/Button/Button"
import getAppUrl from "@src/utils/getAppUrl"
import DotBG from "../../V2/components/DotBG/DotBG"
import CalloutWithDemoForm from "../../../components/CalloutWithDemoForm/CalloutWithDemoForm"
import Column from "../../V2/components/Column/Column"
import Splash from "../../V2/Features/components/Splash"
import { useBreakpoint } from "gatsby-plugin-breakpoints"

import HeaderImage from "@src/icons/industries/technology_header.png"
import SectionOne from "@src/icons/industries/technology_section_one.png"
import SectionTwo from "@src/icons/industries/technology_section_two.png"
import SectionThree from "@src/icons/industries/technology_section_three.png"
import CardOne from "@src/icons/industries/technology_card_one.png"
import CardTwo from "@src/icons/industries/technology_card_two.png"
import CardThree from "@src/icons/industries/technology_card_three.png"
import OG from "@src/icons/og/OG_Technology.jpg"
import * as styles from "./Technology.module.scss"

const Financial = () => {
  const appUrl = getAppUrl.hook()
  const breakpoints = useBreakpoint()

  return (
    <Layout
      title="Streamline Your Technology, Information, and Internet Operations with Canonic"
      description="Create custom solutions tailored to your specific technology, information, and internet needs, without the need for costly development resources or coding expertise."
      url="https://canonic.dev/industries/technology-information-and-internet"
      ogImage={OG}
    >
      <Splash
        title="Streamline Your Technology, Information, and Internet Operations with Canonic"
        subtitle="Create custom solutions tailored to your specific technology, information, and internet needs, without the need for costly development resources or coding expertise."
        illustration={<img src={HeaderImage} />}
        ctas={[
          <a href={appUrl} target="_blank" rel="noopener noreferrer">
            <Button isPrimary>Try for free</Button>
          </a>,
          <a href={"#get-a-demo"}>
            <Button borderless marginLeft>
              Get a Demo
            </Button>
          </a>,
        ]}
        reverseOnMobile
        className={styles.technology}
      />
      <Row>
        {breakpoints.mobile ? (
          <DotBG height={"113%"} reverse />
        ) : (
          <DotBG height={"calc(100% + 256px)"} offset={"-120px"} reverse />
        )}
        <div className={styles.technologySection}>
          <h2>Unlock Agile and Adaptable Operations with No-code</h2>
          <p>
            The technology, information, and internet industry faces many
            challenges such as the need for rapid innovation, keeping up with
            changing customer needs, and the demand for personalized
            experiences. Canonic's No-Code platform offers a solution to these
            challenges by providing a flexible and agile platform that allows
            you to create custom solutions quickly and easily.
          </p>
        </div>
      </Row>
      <section>
        <Row className={styles.technologyImageRow}>
          {breakpoints.mobile ? (
            <DotBG height={"150%"} offset={"-4.5%"} reverse />
          ) : (
            <DotBG height={"calc(100% + 256px)"} offset={"-80px"} reverse />
          )}
          <h2>Business Benefits </h2>
          <ImageRow
            title="Rapid Innovation"
            subtitle="Canonic accelerates innovation by allowing you to quickly iterate and make changes, while also reducing development costs by eliminating the need for costly development resources."
            screenshot={SectionOne}
            borderless
            className={styles.retailProcessSources}
          />
          <ImageRow
            title="Personalized Experience"
            subtitle="Canonic enables you to create personalized experiences for your customers, enhancing customer satisfaction and loyalty."
            screenshot={SectionTwo}
            reverse
            borderless
            className={styles.retailProcessBoost}
          />
          <ImageRow
            title="Agile Operations"
            subtitle="With Canonic, you can create custom solutions quickly and easily, allowing you to stay agile and adapt to changes in the market."
            screenshot={SectionThree}
            borderless
            className={styles.retailTailorMade}
          />
        </Row>
      </section>

      <section>
        <Row className={styles.technologyCards}>
          {breakpoints.mobile ? (
            <DotBG height={"110%"} offset={"-8%"} reverse />
          ) : (
            <DotBG height={"calc(100% + 256px)"} offset={"-165px"} reverse />
          )}
          <h2 className={styles.retailCardsTitle}>
            All the features you can need
          </h2>
          <div className={styles.retailCards}>
            <Column.Wrap>
              <Column
                title="Data Integration"
                icon={CardOne}
                description="Canonic provides easy data integration with existing systems, reducing the need for manual data entry and enhancing the accuracy of data."
              />
              <Column
                title="Workflow Automation"
                icon={CardTwo}
                description={
                  "Canonic enables you to automate workflows, reducing errors, improving efficiency, and freeing up your team's time for more strategic tasks."
                }
              />
              <Column
                title="Cybersecurity Solutions"
                icon={CardThree}
                description={
                  " With Canonic, you can build custom solutions for cybersecurity, such ass automated security monitoring and providing access control management."
                }
              />
            </Column.Wrap>
          </div>
        </Row>
      </section>

      <CalloutWithDemoForm
        originPage="Canonic for Financial"
        id="get-a-demo"
        title="Try Canonic today and experience the benefits of streamlined operations, rapid innovation, and personalized customer experiences."
      />
    </Layout>
  )
}

export default Financial
